import React from "react";
import "./Modal.css";

function Modal({ isOpen, onClose, onSubmit }) {
  if (!isOpen) {
    return null;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const newGoal = event.target.elements.goal.value;
    onSubmit(newGoal);
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <h2>Set Your Goal For Tasbih</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="number"
            name="goal"
            min="1"
            placeholder="Enter your goal"
            required
          />
          <div className="button-group">
            <button type="submit">Set Goal</button>
            <button type="button" onClick={onClose}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Modal;
