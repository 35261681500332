import React, { useState, useEffect } from "react";
import "./App.css";
import Modal from "./Modal";

function App() {
  const [count, setCount] = useState(() => {
    const storedCount = localStorage.getItem("count");
    return storedCount ? parseInt(storedCount, 10) : 0;
  });

  const [goalCount, setGoalCount] = useState(() => {
    const storedGoalCount = localStorage.getItem("goalCount");
    return storedGoalCount ? parseInt(storedGoalCount, 10) : 1000;
  });

  const [mainGoal, setMainGoal] = useState(() => {
    const storedMainGoal = localStorage.getItem("mainGoal");
    return storedMainGoal ? parseInt(storedMainGoal, 10) : 1000;
  });

  const [isModalOpen, setIsModalOpen] = useState(true);

  useEffect(() => {
    localStorage.setItem("count", count.toString());
  }, [count]);

  useEffect(() => {
    localStorage.setItem("goalCount", goalCount.toString());
  }, [goalCount]);

  useEffect(() => {
    localStorage.setItem("mainGoal", mainGoal.toString());
  }, [mainGoal]);

  const handleIncrement = () => {
    setCount((prevCount) => prevCount + 1);
    setGoalCount((prevGoalCount) => prevGoalCount - 1);
  };

  const handleReset = () => {
    setCount(0);
    setGoalCount(mainGoal);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalSubmit = (newGoal) => {
    const parsedGoal = parseInt(newGoal, 10);
    if (!isNaN(parsedGoal) && parsedGoal > 0) {
      setMainGoal(parsedGoal);
      setGoalCount(parsedGoal);
      setIsModalOpen(false);
    } else {
      alert("Please enter a valid number greater than 0.");
    }
  };

  return (
    <div className="App">
      <h1>
        <span>[</span> TASBIH <span>]</span>
      </h1>
      <div className="counter">
        <p>Main Goal: {mainGoal}</p>
        <p>
          Counter: <span>{count}</span>
        </p>
        <p>Remaining: {goalCount}</p>
        <div className="contain">
          <button onClick={handleIncrement} className="oof">
            Increment
          </button>
          <button onClick={handleReset} className="ooff">
            Reset Counter
          </button>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSubmit={handleModalSubmit}
      />
    </div>
  );
}

export default App;
